// Meta Links
export const SiteURL = "https://www.abdelmuttalib.com";

// Social Links
export const TwitterURL = "";
export const TwitterUsername = "";
export const GithubURL = "https://github.com/Abdelmuttalib";
export const FigmaURL = "";
export const DribbbleURL = "";
export const LinkedinURL =
  "https://www.linkedin.com/in/abdelmuttalib-ahmed-81919b160/";

export const ResumeURL = "/cv";
